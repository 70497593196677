import React, { ReactNode } from "react";
import { IconLink } from "../common/IconLink/IconLink";
import { logoGithub } from "ionicons/icons";

interface ProjectProps {
  title: string;
  children: ReactNode;
  githubLink?: string;
}

export const Project = ({ title, children, githubLink }: ProjectProps) => (
  <div className="project-container">
    <h1 className="project-title ">{title}</h1>
    {githubLink && (
      <IconLink
        href={githubLink}
        icon={logoGithub}
        accessibilityText="Github"
        openInNewTab
      />
    )}
    <div className="project-content-container">{children}</div>
  </div>
);
