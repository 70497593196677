import React from "react";

const topBottomMargin = "24px";

const sectionDividerStyle = {
  borderBottom: "1px solid #999999",
  marginBottom: topBottomMargin,
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: topBottomMargin,
  width: "40%",
};

export const SectionDivider = () => {
  return <div style={sectionDividerStyle}></div>;
};
