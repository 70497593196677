import React, { useCallback, useContext } from "react";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import { IconButton } from "../../../common/IconButton/IconButton";

export const MenuToggle = () => {
  const { menuIsCollapsed, setMenuIsCollapsed } = useContext(ConfigContext);

  const handleChange = useCallback(() => {
    setMenuIsCollapsed(!menuIsCollapsed);
  }, [menuIsCollapsed, setMenuIsCollapsed]);

  return (
    <div id="menu-toggle">
      <IconButton onClick={handleChange}>
        <div
          className={`menu-toggle-container ${
            !menuIsCollapsed ? "expanded" : ""
          }`}
        >
          <span className="bar topbar"></span>
          <span className="bar bottombar"></span>
        </div>
      </IconButton>
    </div>
  );
};
