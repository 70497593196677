import React from "react";
import { projectConfig } from "../resources/configuration/projectConfig";
import { ProjectLink } from "../components/ProjectLink/ProjectLink";

class Projects extends React.Component {
  render() {
    return (
      <div className="projects-container">
        {projectConfig.projects.map((project) => (
          <ProjectLink
            projectId={project.projectId}
            projectImg={project.projectImg}
            projectName={project.projectName}
          />
        ))}
      </div>
    );
  }
}

export default Projects;
