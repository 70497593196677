import React from "react";
import { Education } from "../components/education/Education";
import { Employment } from "../components/employment/Employment";
import {
  careerContent,
  educationContent,
  skillsContent,
} from "../resources/configuration/textConfig";
import { IonIcon } from "@ionic/react";
import {
  businessOutline,
  constructOutline,
  libraryOutline,
} from "ionicons/icons";
import { SectionDivider } from "../components/common/SectionDivider/SectionDivider";

export const Resume = () => {
  return (
    <div className="container">
      <div className="resume-section">
        <h3 className="resume-section-header">
          <IonIcon
            className="resume-icon"
            icon={businessOutline}
            aria-hidden="true"
            size="medium"
          />
          <span className="resume-section-title">Job Experience</span>
        </h3>
        <ul>
          {careerContent.jobs.map((job, index) => (
            <>
              <Employment key={"job" + index} employer={job} />
              {index < careerContent.jobs.length - 1 && <SectionDivider />}
            </>
          ))}
        </ul>
      </div>
      <div className="resume-section">
        <h3 className="resume-section-header">
          <IonIcon
            className="resume-icon"
            icon={constructOutline}
            aria-hidden="true"
            size="medium"
          />
          <span className="resume-section-title">Technical Skills</span>
        </h3>
        <ul className="techSkillList">
          {skillsContent.skills.map((skill) => (
            <p key={"tag-" + skill.replace(" ", "-")} className="resume-skill">
              {skill}
            </p>
          ))}
        </ul>
      </div>
      <div className="resume-section">
        <h3 className="resume-section-header">
          <IonIcon
            className="resume-icon"
            icon={libraryOutline}
            aria-hidden="true"
            size="medium"
          />
          <span className="resume-section-title">Education</span>
        </h3>
        <ul>
          {educationContent.institutions.map((school, index) => (
            <Education
              key={"institution-" + index}
              institution={school.institution}
              degrees={school.degrees}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};
