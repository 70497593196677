export const homeContent = {
  introduction:
    "I'm an enthusiastic engineer with over 10 years of experience building full stack applications, based in the District of Columbia.  I am particularly passionate about front end development and creating pleasing and accessible user experiences.  Take a look at my resume for more information!",
  nonWorkInfo:
    "Outside of work, I'm an avid runner and climber who enjoys baking various breads and confections.",
  emailLink: "mailto:tom@tompotter.dev",
  githubLink: "https://github.com/tpotter?tab=repositories",
  linkedInLink: "https://www.linkedin.com/in/tom-potter-dev/",
};

// I am an enthusiastic developer with over 10 years of experience building full stack applications.

export const skillsContent = {
  skills: [
    "Javascript",
    "Typescript",
    "ReactJS",
    "NodeJS",
    "HTML5",
    "GIT",
    "SCSS",
    "Java",
    "Spring Boot",
    "Oracle",
    "MySQL",
    "Cloud",
    "OWASP Best Practices",
    "System Hardening",
    "Vulnerability Remediation",
    "Agile Methodologies",
    "Production Support and Debugging",
  ],
};

export const careerContent = {
  jobs: [
    {
      name: "Tanium",
      jobTitle: "Staff Software Engineer",
      dates: "October 2021 - Present",
      functions: [
        "Team lead for module squad that is responsible for the development of 4 products.",
        "Team lead of squad that enhances and maintains the company-wide frontend code monorepo.",
        "SRE and L3 on call duties",
        "Implemented localization and internationalization standards.",
        "Updated and improved adoption of WCAG accessibility standards within the product.",
        "Coordinated product content releases.",
      ],
    },
    {
      name: "JP Morgan Chase & Co.",
      jobTitle: "Associate Software Engineer",
      dates: "February 2012 - September 2021",
      functions: [
        "Led Java development team in the decom of legacy infrastructure resulting in annual savings of 30 million USD",
        "Built external facing, microservice based APIs capable of handling high transaction volumes (100s of TPS)",
        "Designed and built internal applications which automated developer BAU activities freeing up 70% of sprint capacity",
        "Scrum master for multiple engineering teams",
        "Application security specialist tasked with identifying and removing vulnerabilities from a suite of applications across the line of business",
        "Coordinated hands-on requirements gathering and iterative design sessions with key business stakeholders",
      ],
    },
  ],
};

export const educationContent = {
  institutions: [
    {
      institution: "University of Delaware",
      degrees: [
        {
          type: "M.S. Computer Engineering CyberSecurity Concentration",
          year: "December 2019",
        },
        {
          type: "M.B.A.",
          year: "December 2019",
        },
        {
          type: "B.S. Electrical Engineering w/ CS Minor",
          year: "May 2013",
        },
      ],
    },
  ],
};
