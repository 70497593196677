import React from "react";
import { BottomBar } from "../components/BottomBar/BottomBar";
import { homeContent } from "../resources/configuration/textConfig";

export const Home = () => {
  return (
    <div className="home-container">
      <div className="content-flex">
        <p className="home-text">{homeContent.introduction}</p>
        <img id="profile-image" src="/images/profile.jpg" alt="Tom Potter" />
      </div>
      <BottomBar />
    </div>
  );
};
