import React from "react";

interface Degree {
  type: string;
  year: string;
}

export interface EducationProps {
  degrees: Degree[];
  institution: string;
}

export const Education = ({ degrees, institution }: EducationProps) => (
  <div className="edu-container">
    <p className="school-name">{institution}</p>
    <ul className="edu-ul">
      {degrees.map((degree, index) => (
        <p key={"degree" + index} className="degree-listing">
          {degree.type + " - " + degree.year}
        </p>
      ))}
    </ul>
  </div>
);
