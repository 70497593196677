import React, { useContext, useMemo } from "react";

import { Link } from "react-router-dom";
import { ConfigContext } from "../../../contexts/ConfigContext";
import { AccessibilityMenu } from "../../accessibility-tools/AccessibilityMenu";
import { MenuToggle } from "./menu-toggle/MenuToggle";

const Menu = ({ options }) => {
  const { isMobile, menuIsCollapsed, setMenuIsCollapsed } =
    useContext(ConfigContext);

  const optionLinks = useMemo(
    () =>
      options.map((option) => (
        <Link
          to={option.path}
          key={"header-item-" + option.id}
          className="header-link"
          tabIndex={0}
          onClick={
            isMobile
              ? () => {
                  setMenuIsCollapsed(true);
                }
              : undefined
          }
        >
          {option.name}
        </Link>
      )),
    [isMobile, options, setMenuIsCollapsed],
  );

  return isMobile ? (
    <div className={menuIsCollapsed ? "mobile-view" : "mobile-view expanded"}>
      {optionLinks}
      <AccessibilityMenu />
    </div>
  ) : (
    <div className="wide-view">{optionLinks}</div>
  );
};

export const HeaderMenu = ({ options, currentFont, setCurrentFont }) => {
  const { isMobile } = useContext(ConfigContext);

  return (
    <div className="menu-container">
      {isMobile && <MenuToggle id="menu-toggle-switch" />}
      <Menu options={options} />
    </div>
  );
};
