import React from "react";

interface IconButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

export const IconButton = ({ children, onClick }: IconButtonProps) => {
  return (
    <button className="icon-button" onClick={onClick}>
      {children}
    </button>
  );
};
