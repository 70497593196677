import { IonIcon } from "@ionic/react";
import { accessibilityOutline } from "ionicons/icons";
import React, { useCallback, useContext, useState } from "react";
import { ConfigContext } from "../../contexts/ConfigContext";
import {
  ACCESSIBILITY_ICON_CLOSED,
  ACCESSIBILITY_ICON_OPEN,
} from "../../themeDefinitions";

export const AccessibilityMenu = () => {
  const { currentFont, setCurrentFont } = useContext(ConfigContext);
  const [accMenuIsVisible, setAccMenuIsVisible] = useState(false);

  const handleFontChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentFont(event.target.value);
    },
    [setCurrentFont],
  );

  const toggleAccessibilityMenu = useCallback(() => {
    setAccMenuIsVisible(!accMenuIsVisible);
  }, [accMenuIsVisible]);

  return (
    <div className="accessibility-menu">
      <button
        onClick={toggleAccessibilityMenu}
        style={{
          borderRadius: "50%",
          border: 0,
          display: "inline-block",
          cursor: "pointer",
          height: "50px",
          marginLeft: "8px",
          padding: "4px",
          width: "50px",
        }}
      >
        <IonIcon
          icon={accessibilityOutline}
          size="large"
          color={
            accMenuIsVisible
              ? ACCESSIBILITY_ICON_OPEN
              : ACCESSIBILITY_ICON_CLOSED
          }
        />
      </button>
      <div
        className={`menu-form-container ${
          accMenuIsVisible ? "container-expanded" : "container-collapsed"
        }`}
      >
        <form className="access-menu-form">
          <h2 className="header-link">Font Options</h2>

          <input
            id="default"
            type="radio"
            name="font"
            value="Polaris"
            checked={currentFont === "Polaris"}
            onChange={handleFontChange}
          />
          <label id="default-label" htmlFor="default">
            Default
          </label>
          <br />

          <input
            id="atkinson"
            type="radio"
            name="font"
            value="Atkinson-Hyperlegible"
            checked={currentFont === "Atkinson-Hyperlegible"}
            onChange={handleFontChange}
          />
          <label id="atkinson-label" htmlFor="atkinson">
            Atkinson HyperLegible
          </label>
          <br />

          <input
            id="dyslexie"
            type="radio"
            name="font"
            value="Open-Dyslexic"
            checked={currentFont === "Open-Dyslexic"}
            onChange={handleFontChange}
          />
          <label id="dyslexie-label" htmlFor="dyslexie">
            Open Dyslexic
          </label>
        </form>
      </div>
    </div>
  );
};
