export const sectionConfig = {
  sections: [
    {
      id: "home",
      name: "Home",
      path: "/",
    },
    {
      id: "resume",
      name: "Resume",
      path: "/resume",
    },
    /* {
      id: "projects",
      name: "Projects",
      path: "/projects",
    },
    */
  ],
};
