import React, { useEffect, useState } from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Header } from "./components/header/Header";
import { Home } from "./sections/Home";
import Projects from "./sections/Projects";
import { AccessibilityMenu } from "./components/accessibility-tools/AccessibilityMenu";
import { ConfigContext } from "./contexts/ConfigContext";
import { Resume } from "./sections/Resume";
import { MirrorMirror } from "./pages/projects/MirrorMirror";

export const App = () => {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches,
  );
  const [currentFont, setCurrentFont] = useState("Polaris");
  const [menuIsCollapsed, setMenuIsCollapsed] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    });
  }, []);

  return (
    <Router>
      <ConfigContext.Provider
        value={{
          isMobile,
          currentFont,
          setCurrentFont,
          menuIsCollapsed,
          setMenuIsCollapsed,
        }}
      >
        <div
          id="body-container"
          style={{
            fontFamily: currentFont,
          }}
        >
          <Header />
          <div className="content-container">
            <Switch>
              <Route path="/resume">
                <Resume />
              </Route>
              <Route path="/projects">
                <Projects />
              </Route>
              {/** Projects */}
              <Route path="/project/mirrormirror">
                <MirrorMirror />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
            </Switch>
          </div>
          {!isMobile && <AccessibilityMenu />}
        </div>
      </ConfigContext.Provider>
    </Router>
  );
};
