import React from "react";

import { HeaderMenu } from "./header-menu/HeaderMenu";
import { sectionConfig } from "../../resources/configuration/sectionConfig";

export const Header = () => {
  return (
    <nav className="navbar-container">
      <h1 className="title-text">
        <span className="title-first">TOM</span>
        <span className="title-second">POTTER</span>
      </h1>
      <HeaderMenu options={sectionConfig.sections} />
    </nav>
  );
};
