import { logoGithub, logoLinkedin, mailOutline } from "ionicons/icons";
import React from "react";
import { IconLink } from "../common/IconLink/IconLink";
import { homeContent } from "../../resources/configuration/textConfig";

export const BottomBar = () => {
  return (
    <div id="home-icons" className="bottom-bar-container">
      <IconLink
        href={homeContent.emailLink}
        icon={mailOutline}
        accessibilityText="TOM@tompotter.dev"
        openInNewTab
      />
      <IconLink
        href={homeContent.githubLink}
        icon={logoGithub}
        accessibilityText="Github"
        openInNewTab
      />
      <IconLink
        href={homeContent.linkedInLink}
        icon={logoLinkedin}
        accessibilityText="LinkedIn"
        openInNewTab
      />
    </div>
  );
};
