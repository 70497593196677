const IMG_BASE_PATH = "images";

export const projectConfig = {
  projects: [
    {
      projectName: "Mirror Mirror",
      projectId: 1,
      projectImg: `${IMG_BASE_PATH}/sample.png`,
    },
    {
      projectName: "Glimmer",
      projectId: 2,
      projectImg: `${IMG_BASE_PATH}/sample.png`,
    },
    {
      projectName: "Homepage",
      projectId: 3,
      projectImg: `${IMG_BASE_PATH}/sample.png`,
    },
  ],
};
