import React from "react";

export interface ProjectLinkProps {
  projectId: number;
  projectImg: string;
  projectName: string;
}

export const ProjectLink = ({
  projectId,
  projectImg,
  projectName,
}: ProjectLinkProps) => {
  return (
    <div
      className="project-link-container"
      style={{
        backgroundImage: `url(${projectImg})`,
        backgroundSize: "cover",
      }}
    >
      <h3 className="project-link-title">{projectName}</h3>
    </div>
  );
};
