import { IonIcon } from "@ionic/react";
import React from "react";

interface IconLinkProps {
  href: string;
  icon: string;
  accessibilityText: string;
  openInNewTab: boolean;
}

export const IconLink = ({
  href,
  icon,
  accessibilityText,
  openInNewTab,
}: IconLinkProps) => {
  return (
    <a
      href={href}
      aria-label={accessibilityText}
      target={openInNewTab ? "_blank" : undefined}
      rel={openInNewTab ? "noreferrer" : ""}
      style={{
        borderRadius: "50%",
        height: "32px",
        padding: "4px",
        width: "32px",
      }}
    >
      <IonIcon icon={icon} aria-hidden="true" size="large" />
    </a>
  );
};
