import React from "react";
import { Project } from "../../components/Project/Project";

export const MirrorMirror = () => {
  return (
    <Project
      title="Mirror Mirror"
      githubLink="https://github.com/tpotter/mirrormirror2"
    >
      <p>
        Like pretty much everyone else that saw a smart mirror for the first
        time, I thought it was the coolest thing ever and wanted to make one
        myself. When I relocated to Washington DC and became reliant on public
        transit for getting around the city I figured I had to perfect excuse to
        finally give it a go. I was aware that open source solutions existed,
        but I generally felt that those interfaces often seemed a bit cluttered.
        A lot of data density could be achieved, but I wanted to give mine a
        more minimalist aesthetic. Even beyond the appearance, building
        something yourself is a lot more fun!
      </p>
      <h3>Design Considerations</h3>
      <p>
        I already had a spare monitor with a decent resolution that I could use
        for the display on had, so this would become the baseline for the other
        dimensions of the mirror. It was on the smaller side, measuring only
        about 8 by 12 inches, so in order to maintain the cleaner look and feel
        I was going to need to find a creative way of orgainizing all of the
        data that I wanted to. The most obvious solution was to implement
        paging, but that would mean that I'd need to determine a way to allow a
        user to cycle through the different pages. I looked into several
        different options, ranging from physical buttons to gesture recognition,
        however, nothing seemed to have the right balance of reducing complexity
        while also remaining visually consistent. Then I stumbled across a{" "}
        <a
          href="https://www.adafruit.com/product/2340?gclid=EAIaIQobChMIo9OOpaGh7gIVy8DACh06BgR3EAQYASABEgI3OvD_BwE"
          target="blank"
          rel="noreferrer"
        >
          capacitive touch sensor
        </a>{" "}
        that was compatible with a Raspberry Pi. Perfect! This would provide
        touch controls by letting me programatically assigning it to a keyboard
        key, while allowing me to appropriately customize the visible touch
        pads. With that in place, all of the hardware was settled.
      </p>
      {/* TODO: Insert Hardware Diagram Here */}
      <p>
        For the arrangement of the UI itself, I wanted to keep everything as
        modular as possible in order to support maximum flexibility as I added,
        removed and redesigned functionality. The mirror view would consist of a
        collection of pages, where only one page would be displayed at a time
        with the current page denoted by a bar at the bottom. As the user
        triggered controls on the side of the mirror enclosure, the current page
        would slide to show a new one. A page would include a relatively
        positioned display area on which a group of widgets would be arranged. A
        widget would be the base unit of the mirror and each individual one
        would be responsible for a distinct piece of functionality. Each widget
        that would be displayed would have a configuration object loaded to it
        that would specify where it appears on the page and provides any
        additional details needed to function properly.
      </p>
      <h3>Current Widgets</h3>

      <h4>Date & Time</h4>
      <p>Just like the title says, this one displays the date and time.</p>

      <h4>Metro</h4>
      <p>
        Being reliant on the metro system, knowing exactly when a train is going
        to get to the station is invaluable for avoiding long wait times at the
        platform. This widget utilizes the WMATA api to retrieve details for
        train arrivals from a designated station. The raw data is organized by
        line and destination and then trains are sorted by the nearest arrival
        time.
      </p>

      <h4>AQI Reading</h4>
      <p>
        I added this widget during a time where wildfires were causing air
        quality warnings in my area so I could avoid running if the particulate
        levels were too high. The functionality is very straightforward, it
        integrates with the EPA's AirNow api and then displays the raw value on
        a color coded background.
      </p>
    </Project>
  );
};
