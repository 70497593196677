import React from "react";

interface Employer {
  name: string;
  jobTitle: string;
  dates: string;
  functions: string[];
}

interface EmploymentProps {
  employer: Employer;
}

export const Employment = ({ employer }: EmploymentProps) => {
  console.log(employer);
  return (
    <div className="employment-container">
      <div className="employer-header">
        <h3 className="employer-name">{employer.name}</h3>
        <h3 className="job-title">{employer.jobTitle}</h3>
        <h4 className="employer-dates">{employer.dates}</h4>
      </div>
      <ul className="job-functions">
        {employer.functions.map((role, index) => (
          <li key={"jobFunction" + index} className="job-function">
            {role}
          </li>
        ))}
      </ul>
    </div>
  );
};
